import {get,post} from "@/utils/axios"

const getCarList = p => get("/api-common/softwareCarPortal/getSoftwareCarList", p)

const synchroCartNum = p => post("/api-common/softwareCarPortal/syncSoftwareCarUpdate", p)

const removeCart = p => post("/api-common/softwareCarPortal/syncSoftwareCarDel", p)

export default {
    getCarList,
    synchroCartNum,
    removeCart,
}