<template>
    <el-row class="shopping-cart">
        <div class="cart-title">
            <div class="center-title">我的购物车</div>
            <div class="right-title">继续购物</div>
        </div>
        <div class="cart-handle">
            <div class="handle-btn">
                <input class="cart-radio" type="radio" :checked="checkCarts.length == cartList.length" @click="allCheck"></input>
            </div>
            <div class="handle-allcheck" @click="allCheck">全选</div>
            <div class="handle-product">商品</div>
            <div class="handle-time">时长</div>
            <div class="handle-price">单价</div>
            <div class="handle-num">数量</div>
            <div class="handle-sum">小计</div>
            <div class="handle-cz">操作</div>
        </div>
        <div class="cart-list">
            <div
                 class="cart-item"
                 v-for="(item,idx) in cartList"
                 :key="idx"
            >
                <div class="item-check">
                    <input class="cart-radio" type="radio" :checked="checkCarts && checkCarts.indexOf(item.softwareCarId) > -1" @click="radioClick(item.softwareCarId)"></input>
                </div>
                <div class="item-img">
                    <img :src="item.imgUrl" @click="radioClick(item.softwareCarId)">
                </div>
                <div class="item-product">
                    <div class="product-title">{{ item.softwareGoodsName }}</div>
                    <div class="product-spaces">{{ item.specsTypeName }}</div>
                </div>
                <div class="item-time">{{ item.yearOfPurchase }}年</div>
                <div class="item-price">￥{{ item.goodsPrice }}</div>
                <div class="item-num">
                    <el-input-number v-model="item.number" :min="1" @change="numberChange(item)"></el-input-number>
                </div>
                <div class="item-sum">￥{{ item.softwareAllPrice }}</div>
                <div class="item-cz">
                    <span class="product-remove" @click="removeProduct(item.softwareCarId)">删除</span>
                </div>
            </div>
        </div>
        <div class="cart-empty" v-if="cartList.length === 0">
            <img src="../../img/shoppingCart/cart_empty.png">
        </div>
        <div class="cart-footer">
            <div class="footer-radio">
                <input type="radio" class="cart-radio" :checked="checkCarts.length == cartList.length" @click="allCheck"></input>
            </div>
            <div class="footer-radio" @click="allCheck">全选</div>
            <div class="footer-clear" @click="removeCheck">删除选中商品</div>
            <div class="footer-check">已选择&nbsp;<span class="check-num">{{ allNum }}</span>&nbsp;件商品</div>
            <div class="footer-sum">总价：</div>
            <div class="sum-price">￥{{ sumPrice }}</div>
            <div class="footer-close">
                <el-button>去结算</el-button>
            </div>
        </div>
    </el-row>
</template>

<script>
import api from '@/api/carApi.js'
export default {
    name: "shoppingCart",
    data() {
        return {
            val: '',
            num: 0,
            cartList: [
            ],
            checkCarts: [],
            allNum: 0,
        }
    },
    computed: {
      sumPrice() {
        let price = 0;
        let allNum = 0;
        this.cartList.forEach(item => {
            if(this.checkCarts && this.checkCarts.indexOf(item.softwareCarId) > -1) {
                price += item.softwareAllPrice;
                allNum += item.number;
            }
        });
        this.allNum = allNum;
        return price;
      }
    },
    mounted() {
        this.listCart(() => {
            let cartIds = [];
            this.cartList.forEach(item => {
                cartIds.push(item.softwareCarId);
            });
            this.checkCarts = cartIds;
        });
    },
    methods: {
        //购物车列表
        listCart(getIds) {
            api.getCarList({}).then(res => {
                this.cartList = res.data;
                if(getIds) {
                    getIds();
                }
            })
        },
        numberChange(val) {
            this.updateCartNum(val.softwareCarId, val.number);
        },
        updateCartNum(id, number) {
          api.synchroCartNum({
              softwareCarId: id,
              number: number
          }).then(res => {
              if(res.code !== 200) {
                  this.$message.error(res.message);
                  return;
              }
              this.listCart();
          })
        },
        //删除商品
        removeProduct(id) {
            this.$confirm('您确定删除该商品吗？', '提示').then(() => {
                // let cartIds = [id];
                api.removeCart([id]).then(res => {
                    if(res.code != 200) {
                        this.$message.error(res.message);
                        return;
                    }
                    this.listCart();
                })
            })
        },
        //选中商品
        radioClick(id) {
            if(!this.checkCarts) {
                this.checkCarts = [];
            }
            if(this.checkCarts.indexOf(id) > -1) {
                let index = this.checkCarts.indexOf(id);
                this.checkCarts.splice(index, 1);
            }else {
                this.checkCarts.push(id);
            }
        },
        //全选
        allCheck() {
            if(this.checkCarts.length == this.cartList.length) {
                this.checkCarts = [];
                return;
            }
            let ids = [];
            this.cartList.forEach(item => {
                ids.push(item.softwareCarId);
            })
            this.checkCarts = ids;
        },
        //删除选中
        removeCheck() {
            if(!this.checkCarts || this.checkCarts.length == 0) {
                this.$message.warning('暂无选中商品');
                return;
            }
            let newCart =  [];
            this.cartList.forEach(item => {
                if(this.checkCarts.indexOf(item.softwareCarId) == -1) {
                    newCart.push(item);
                }
            });
            this.cartList = newCart;
            this.checkCarts = [];
        }
    },
}
</script>

<style scoped>
.shopping-cart {
    font-size: 14px;
    width: 63%;
    margin: 1% auto;
}
.cart-title {
    display: flex;
    height: 80px;
    justify-content: center;
}
.center-title {
    width: 52%;
    text-align: right;
    font-size: 24px;
    font-weight: bold;
    color: #414141;
    margin-top: 2%;
}
.right-title {
    width: 47%;
    text-align: right;
    font-size: 14px;
    color: #D60010;
    margin-top: 2.5%;
    margin-right: 1%;
    cursor: pointer;
}
.cart-handle {
    display: flex;
    font-size: 16px;
}
.handle-btn {

}
.handle-allcheck {
    margin-left: 1%;
    width: 8%;
    cursor: pointer;
}
.handle-product {
    margin-left: 4.5%;
    width: 24%;
}
.handle-time {
    margin-left: 10.5%;
    width: 5%;
}
.handle-price {
    margin-left: 3%;
    width: 8%;
    text-align: center;
}
.handle-num {
    margin-left: 1.5%;
    width: 10.5%;
    text-align: center;
}
.handle-sum {
    margin-left: 4%;
    width: 8%;
    text-align: center;
}
.handle-cz {
    margin-left: 4.5%;
    width: 3%;
    text-align: center;
}
.cart-item {
    display: flex;
    height: 180px;
    margin: 1% 0%;
}
.item-check {
    margin-top: 6%;
}
.item-img {
    margin-left: 1%;
    margin-top: 3%;
    width: 8%;
}
.item-img img {
    width: 70%;
    height: 70%;
    cursor: pointer;
}
.item-product {
    margin-left: 4.5%;
    width: 24%;
    margin-top: 4%;
}
.product-title {
    font-size: 16px;
    font-weight: bold;
    color: #414141;
}
.product-spaces {
    margin-top: 5%;
}
.item-time {
    margin-left: 10.5%;
    margin-top: 6%;
    width: 5%;
}
.item-price {
    margin-left: 3%;
    margin-top: 6%;
    width: 8%;
    text-align: center;
}
.item-num {
    margin-left: 1.5%;
    margin-top: 5%;
    width: 10%;
}
/deep/ .item-num .el-input-number {
    width: 125px;
}
.item-sum {
    margin-left: 4%;
    margin-top: 6%;
    width: 8%;
    font-size: 14px;
    font-weight: bold;
    color: #D60010;
    text-align: center;
}
.item-cz {
    margin-left: 4.5%;
    margin-top: 6%;
    width: 3%;
    text-align: center;
}
.product-remove {
    cursor: pointer;
}
.product-remove:hover {
    font-size: 14px;
    color: #D60010;
}
.cart-footer {
    display: flex;
    height: 80px;
    margin-top: 1%;
    margin-bottom: 1%;
}
.footer-radio {
    margin-top: 2.5%;
    cursor: pointer;
}
.footer-clear {
    margin-left: 4%;
    margin-top: 2.5%;
    cursor: pointer;
}
.footer-clear:hover {
    color: #D60010;
}
.footer-check {
    margin-left: 35%;
    margin-top: 2.5%;
}
.check-num {
    color: #D60010;
}
.footer-sum {
    margin-left: 4%;
    margin-top: 2.5%;
}
.sum-price {
    font-size: 24px;
    font-weight: 600;
    color: #D60010;
    margin-top: 1.9%;
}
.footer-close {
    margin-left: 7%;
    margin-top: 1%;
}
.cart-radio {
    width: 15px;
    height: 15px;
}
.cart-empty {
    text-align: center;
    padding: 10% 0%;
}
/deep/ .footer-close .el-button {
    width: 194px;
    height: 54px;
    background: #D60010;
    border-radius: 27px;
}
/deep/ .footer-close .el-button span {
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
}
/deep/ .cart-handle .el-radio__label {
    font-size: 16px;
}
/deep/ .cart-list .el-input-number__decrease{
    border-right: 1px solid #F56E65;
}
/deep/ .cart-list .el-input-number__decrease .el-icon-minus{
    color: #F56E65;
}
/deep/ .cart-list .el-input-number__increase{
    border-left: 1px solid #F56E65;
}
/deep/ .cart-list .el-input-number__increase .el-icon-plus{
    color: #F56E65;
}
/deep/ .cart-list .el-input-number .el-input__inner {
    border: 1px solid #F56E65;
    height: 40px;
}
</style>